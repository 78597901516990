<template>
  <div class="layout">
    <Header :showLogo="true" logoOnlyOn="desktop" pageName="tour" />
    <div class="upcoming">
      <h2>Tour</h2>
      <h4>There are no upcoming events.</h4>
    </div>
    <Footer cornerText="Foto: Synne Sofi" />
  </div>
</template>

<script>
import { useRouter } from "vue-router";

import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "TourPage",
  components: {
    Header,
    Footer,
  },
  setup() {
    const router = useRouter();

    function navigateToPage(page) {
      router.push(page === "home" ? "/" : "/" + page + "/");
    }

    return {
      navigateToPage,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/main.scss";

.layout {
  background-image: url("@/assets/images/live-background-edited.jpg");
  background-size: 150%;
  background-position: 30% 100%;
  height: 90vh;
  width: -webkit-fill-available;
  display: grid;
  grid-template-rows: max-content;
  grid-row-gap: 5vh;
  justify-items: center;
  padding: 5vh 5vw;

  @media (min-width: $desktop) {
    background-image: url("@/assets/images/live-background-edited.jpg");
    background-size: cover;
    background-position: 0% 18%;
  }

  .upcoming {
    text-align: -webkit-center;

    h2 {
      margin-bottom: 30px;
    }
  }
}
</style>
