<template>
  <div class="additional">
    <div class="corner-text">{{ cornerText }}</div>
    <div class="social-media">
      <img src="@/assets/svg/facebook-svgrepo-com.svg" @click="navigateToLink('Facebook')" />
      <img src="@/assets/svg/instagram-svgrepo-com.svg" @click="navigateToLink('Instagram')" />
      <img src="@/assets/svg/x.svg" @click="navigateToLink('Twitter')" />
    </div>
  </div>
</template>

<script>

export default {
  name: "FooterComponent",
  props: {
    cornerText: {
      type: String
    }
  },
  setup(props) {

    var mobileLayout = props.cornerText ? "4fr 1fr" : "4fr"

    function navigateToLink(mediaType) {
      if (mediaType === "Facebook") {
        window.location =
          "https://www.facebook.com/profile.php?id=61557620197424";
      } else if (mediaType === "Instagram") {
        window.location =
          "https://www.instagram.com/dateofbirthband/?igsh=MWQxcm1vdGhnNTdtbA%3D%3D";
      } else if (mediaType === "Twitter") {
        window.location = "https://twitter.com/dateofbirthband";
      }
    }

    return {
      navigateToLink,
      mobileLayout
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/main.scss";



.additional {
  animation: fadeIn 1.5s;
  display: grid;
  grid-template-rows: v-bind(mobileLayout);
  grid-template-columns: auto;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  .corner-text {
    font-size: 1rem;
    color: #fcd94b;
    font-family: "Montserrat Alternates", "Times New Roman", Times, serif;
    font-weight: bolder;
    -webkit-filter: drop-shadow(1px 1px 1px #222);
    filter: drop-shadow(1px 1px 1px #222);
    text-align: center;

    @media (min-width: $desktop) {
      text-align: left;

    }
  }

  .social-media {
    display: flex;
    column-gap: 25px;
    order: -1;

    img {
      width: 37.5px;
      cursor: pointer;
      filter: drop-shadow(0px 0px 0px #222) drop-shadow(0.5px 1px 0.25px #222) drop-shadow(2px 1px 1px #222) drop-shadow(1px 1px 2px #222);
    }

    @media (min-width: $desktop) {
      order: 1;
    }
  }

  justify-content: center;
  align-items: end;
  justify-self: center;

  @media (min-width: $desktop) {
    justify-content: space-between;
    width: 100%;
    display: flex;
  }
}
</style>