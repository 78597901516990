<template>
  <div class="app">
    <RouterView class="router-view" v-slot="{ Component }">
      <Transition name="page-opacity" mode="out-in">
        <component :is="Component" />
      </Transition>
    </RouterView>
  </div>
</template>

<script>

export default {
  name: "App",
};
</script>

<style lang="scss" scoped>

.page-opacity-enter-active,
.page-opacity-leave-active {
  transition: 600ms ease all;
}

.page-opacity-enter-from,
.page-opacity-leave-to {
  opacity: 0;
}

.app {
  background-color: #fcd94b;

  .header {
    width: -webkit-fill-available;
  }
}

#app {
  font-size: 30px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;

  .header {
    position: absolute;
  }
}
</style>
