<template>
  <div class="layout">
    <Header :showLogo="true" pageName="home" />
    <Footer cornerText="Foto: Synne Sofi" />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "HomePage",
  components: {
    Header,
    Footer,
  },
  setup() {
    function navigateToLink(mediaType) {
      if (mediaType === "Facebook") {
        window.location =
          "https://www.facebook.com/profile.php?id=61557620197424";
      } else if (mediaType === "Instagram") {
        window.location =
          "https://www.instagram.com/dateofbirthband/?igsh=MWQxcm1vdGhnNTdtbA%3D%3D";
      } else if (mediaType === "Twitter") {
        window.location = "https://twitter.com/dateofbirthband";
      }
    }

    return {
      navigateToLink,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/main.scss";

.layout {
  background-image: url("@/assets/images/new-home-background.jpg");
  background-position: 57.5%;
  background-size: cover;
  height: 90vh;
  width: -webkit-fill-available;
  display: grid;
  grid-template-rows: max-content;
  grid-row-gap: 5vh;
  justify-items: center;
  padding: 5vh 5vw;

  @media (min-width: $desktop) {
    background-position: center;
    height: 90vh;
  }

  button {
    color: #fcd94b !important;

    &:hover {
      color: #ae9223 !important;
    }
  }

  img {
    width: 80vw;
    -webkit-filter: drop-shadow(1px 1px 1px #222);
    filter: drop-shadow(1px 1px 1px #222);
    animation: fadeIn 1.5s;

    @keyframes fadeIn {
      0% {
        opacity: 0;
      }

      100% {
        opacity: 1;
      }
    }

    @media (min-width: $desktop) {
      width: 42.5vw;
    }
  }
}
</style>
