<template>
  <div class="layout">
    <Header :showLogo="true" logoOnlyOn="desktop" pageName="about" />
    <h2>About</h2>
    <div class="about-content">
      <h3 class="band-name">Date of Birth</h3>
      <img src="@/assets/images/about-background-compressed.jpeg" onload="this.style.opacity=1" />
      <div class="photo-credit">Foto: Synne Sofi</div>
      <p>
        Embarking on a sonic odyssey, Date of Birth emerges with their ethereal
        melodies, vocals and arrangements. From the vibrant music scene of
        Bergen, Norway, the band is led by Birgitta Alida Hole and Pablo Tellez,
        each with their own experiences and inspirations forming their unique
        sound.
      </p>
      <p>
        Singer and songwriter Birgitta Alida Hole, born and raised in Tromsø, was the lead singer in the bands Strange
        Hellos and Lumikide, before she started her solo-project, Birgitta Alida. Together with bass player, Pablo
        Tellez, who has been actively involved in multiple bands (Young Dreams, John Olav Nilsen & Nordsjøen), they have
        collaborated extensively since 2017 on Birgitta's solo project. The music garnered praise both in Norway and on
        the international scene, getting plenty of airtime at radio stations such as NRK P13, P1 and BBC 6 Music. They
        discovered a creative chemistry that compelled them to delve deeper into their musical partnership. This led to
        Date of Birth.
      </p>
      <p>
        The duo records and produces their music entirely in-house at their little studio in an old meat factory called
        Bergen Kjøtt. Inspired by a distinct array of genres and artists, Date of Birth draws upon influences ranging
        from the dreamy compositions of Debussy to the sacral melodies of Weyes Blood, and the innovative arrangements
        of the Lemon Twigs.
      </p>
      <p>
        Blending genres like classical, folk and indie rock, and a touch of Beales-esque charm, the band crafts a sound
        that is both timeless and original. Their music explores themes of love, loss, self- discovery, and
        interpersonal relations, drawing inspiration from their own experiences and observations of the world around
        them.
      </p>
      <p>
        With musicians Jakob Hjelmeland (drums), Jonas Særsten (keys), and Eirik Marinius Sandvik (guitars) on stage,
        each with a foot in various projects (Real Jobs, Heatwaves and Atari), Date of Birth is ready to present an
        atmospheric live experience.
      </p>
      <p>
        They are currently finishing up their debut album and are soon ready to drop their first release!
      </p>
    </div>
    <Footer />
  </div>
</template>

<script>
import { useRouter } from "vue-router";

import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "AboutPage",
  components: {
    Header,
    Footer,
  },
  setup() {
    const router = useRouter();

    function navigateToPage(page) {
      router.push(page === "home" ? "/" : "/" + page + "/");
    }

    return {
      navigateToPage,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/main.scss";

.layout {
  background-color: #7089ae;
  background-position: 0% 75%;
  background-size: cover;
  min-height: 90vh;
  width: -webkit-fill-available;
  display: grid;
  grid-template-rows: max-content;
  grid-row-gap: 5vh;
  justify-items: center;
  padding: 5vh 5vw;

  .header {
    justify-items: center;

    @media (min-width: $desktop) {
      display: grid;
      grid-template-rows: auto;
      grid-template-columns: 1fr 4fr 1fr;
    }

    img {
      width: 30vw;
      display: none;

      @media (min-width: $desktop) {
        width: 15vw;
        visibility: hidden;
        display: block;
      }
    }
  }

  h2 {
    animation: fadeIn 1s;

    @keyframes fadeIn {
      0% {
        opacity: 0;
      }

      100% {
        opacity: 1;
      }
    }
  }

  .about-content {
    width: 90vw;
    color: black !important;
    // color: #fcd94b !important;

    @media (min-width: $desktop) {
      width: 50vw;
    }

    .photo-credit {
      color: #fcd94b !important;
      -webkit-filter: drop-shadow(1px 1px 1px #222);
      filter: drop-shadow(1px 1px 1px #222);
      font-family: "Montserrat Alternates", "Times New Roman", Times, serif;
      animation: fadeIn 1.5s;
      font-size: .75rem;
    }

    p {
      -webkit-filter: none !important;
      filter: none !important;
      animation: fadeIn 1.5s;
      line-height: 150%;

      @keyframes fadeIn {
        0% {
          opacity: 0;
        }

        50% {
          opacity: 0;
        }

        100% {
          opacity: 1;
        }
      }
    }
  }

  img {
    opacity: 0;
    -webkit-transition: all 2s ease;
    -moz-transition: all 2s ease;
    -ms-transition: all 2s ease;
    -o-transition: all 2s ease;
    width: 90vw;

    @media (min-width: $desktop) {
      width: 50vw;
    }
  }

  .band-name {
    animation: fadeIn 1.5s;

    @keyframes fadeIn {
      0% {
        opacity: 0;
      }

      100% {
        opacity: 1;
      }
    }

    @media (min-width: $desktop) {
      font-size: 2.5rem;
    }
  }
}
</style>
