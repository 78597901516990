import { createApp } from "vue";
//import { store } from "./store.ts";

import App from "./App.vue";
import router from "./router/index.ts";

require("./assets/css/main.scss");

const app = createApp(App);
// app.config.globalProperties.$store = store;
app.use(router);

app.mount("#app");
