import { createWebHistory, createRouter } from "vue-router";

import Home from "@/views/Home.vue";
import About from "@/views/About.vue";
import Tour from "@/views/Tour.vue";
import Store from "@/views/Store.vue";
import Contact from "@/views/Contact.vue";
import Error from "@/views/Error.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
  },
  {
    path: "/about/",
    name: "about",
    component: About,
  },
  {
    path: "/tour/",
    name: "tour",
    component: Tour,
  },
  {
    path: "/store/",
    name: "store",
    component: Store,
  },
  {
    path: "/contact/",
    name: "contact",
    component: Contact,
  },
  {
    path: "/:pathMatch(.*)*",
    name: "error",
    component: Error,
    },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
