<template>
  <div class="layout">
    <div class="content">
      <!-- <button @click="navigateHome">Home</button> -->
      <h2>Store</h2>
      <div class="tbd">Coming soon!</div>
    </div>
    <div class="photo-credit">Foto: Synne Sofi</div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";

export default {
  name: "StorePage",
  setup() {
    const router = useRouter();

    function navigateHome() {
      router.push("/");
    }

    return {
      navigateHome,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/main.scss";

.layout {
  background-image: url("@/assets/images/store-background-compressed.jpeg");
  background-position: 50% 30%;
  background-size: cover;
  height: 90vh;
  width: -webkit-fill-available;
  padding: 5vh 5vw;

  @media (min-width: $desktop) {
    background-position: 0% 40%;
  }


  .content {
    display: grid;
    grid-template-rows: max-content;
    grid-row-gap: 5vh;
    justify-items: center;
    animation: fadeIn 1.5s;

    @keyframes fadeIn {
      0% {
        opacity: 0;
      }

      100% {
        opacity: 1;
      }
    }


    .tbd {
      font-size: 3rem;
      text-align: center;
      color: #fcd94b;
      -webkit-filter: drop-shadow(1px 1px 1px #222);
      filter: drop-shadow(1px 1px 1px #222);

      @media (min-width: $desktop) {
        font-size: 2rem;
        width: 50vw;
      }
    }

    button {
      color: #fcd94b;
      justify-self: left;
      font-size: 1.25rem;
      font-family: "Times New Roman", Times, serif;
      -webkit-filter: drop-shadow(1px 1px 1px #222);
      filter: drop-shadow(1px 1px 1px #222);

      @media (min-width: $desktop) {
        font-size: 1.5rem;
      }

      &::before {
        position: relative;
        content: "";
        display: inline-block;
        width: 0.4em;
        height: 0.4em;
        border-right: 0.2em solid #fcd94b;
        border-top: 0.2em solid #fcd94b;
        transform: rotate(-135deg);
        margin-right: 0.5em;
        transition: 0.3s;
      }

      &:hover {
        color: #ae9223;

        &::before {
          border-right: 0.2em solid #ae9223;
          border-top: 0.2em solid #ae9223;
          transition: 0.3s;
        }
      }
    }
  }

  .photo-credit {
    color: #fcd94b;
    position: absolute;
    margin-bottom: 5vh;
    bottom: 0 !important;
    font-size: 1em;
    font-family: "Times New Roman", Times, serif;
    -webkit-filter: drop-shadow(1px 1px 1px #222);
    filter: drop-shadow(1px 1px 1px #222);
  }
}
</style>
