<template>
    <div class="navigation-bar">
        <img src="@/assets/images/strongLogo.png" @click="navigateToPage('home')" class="desktop-only" />
        <div class="navigation">
            <button :class="setStyle('home')" @click="navigateToPage('home')">Home</button>
            <button :class="setStyle('about')" @click="navigateToPage('about')">About</button>
            <button :class="setStyle('tour')" @click="navigateToPage('tour')">Tour</button>
            <!-- <button @click="navigateToPage('store')">Store</button> -->
            <button :class="setStyle('contact')" @click="navigateToPage('contact')">Contact</button>
        </div>
        <img src="@/assets/images/strongLogo.png" @click="navigateToPage('home')" class="mobile-only" />
    </div>
</template>

<script>
import { useRouter } from "vue-router";

export default {
    name: "HeaderComponent",
    props: {
        darkLayout: {
            type: Boolean
        },
        showLogo: {
            type: Boolean
        },
        logoOnlyOn: {
            type: String
        },
        pageName: {
            type: String
        }
    },
    setup(props) {
        const router = useRouter();

        var navigationColor = props.darkLayout ? "#222" : "#fcd94b"
        var navigationHoverColor = props.darkLayout ? "#4f4f4f" : "#ae9223"
        var logoVisibility = props.showLogo ? "show" : "hidden"
        var logoDisplay = props.showLogo  && (props.logoOnlyOn !== "desktop") ? "block" : "none"

        function setStyle(page) {
            return page === props.pageName ? "active-button" : ""
        }

        function navigateToPage(page) {
            router.push(page === "home" ? "/" : "/" + page + "/");
        }

        return {
            navigateToPage,
            setStyle,
            navigationColor,
            navigationHoverColor,
            logoVisibility,
            logoDisplay
        };
    },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/main.scss";

.navigation-bar {
    justify-items: center;
    display: grid;

    @media (min-width: $desktop) {
        grid-template-rows: auto;
        grid-template-columns: 1fr 4fr 1fr;
    }

    .desktop-only {
        display: none;

        @media (min-width: $desktop) {
            visibility: v-bind(logoVisibility);
            display: block;
        }
    }

    .mobile-only {
        margin-top: 30px;
        display: v-bind(logoDisplay);

        @media (min-width: $desktop) {
            display: none;
        }
    }

    img {
        display: none;
        width: 50vw;
        -webkit-filter: drop-shadow(1px 1px 1px #222);
        filter: drop-shadow(1px 1px 1px #222);
        cursor: pointer;


        @media (min-width: $desktop) {
            display: block;
            width: 15vw;
        }
    }

    .navigation {
        display: flex;
        justify-items: center;
        text-align: center;
        height: fit-content;
        align-self: center;
        animation: fadeIn 1.5s;

        @media (min-width: $desktop) {
            gap: 60px;
        }

        @keyframes fadeIn {
            0% {
                opacity: 0;
            }

            100% {
                opacity: 1;
            }
        }

        button {
            color: v-bind(navigationColor);
            font-size: 1.05rem;
            -webkit-filter: drop-shadow(1px 1px 1px #222);
            filter: drop-shadow(1px 1px 1px #222);

            &:hover {
                color: v-bind(navigationHoverColor);
            }

            @media (min-width: $desktop) {
                font-size: 2.35rem;
            }
        }

        .active-button {
            text-decoration: underline;
            text-underline-offset: 5px;
        }

    }
}
</style>
