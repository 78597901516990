<template>
  <div class="layout">
    <Header :showLogo="true" pageName="error" />
    <div class="content">
      <h2>Oops!</h2>
      <h3>404 - Page not found</h3>
    </div>
  </div>
</template>

<script>
import { useRouter } from "vue-router";

import Header from "@/components/Header.vue";

export default {
  name: "ErrorPage",
  components: {
    Header,
  },
  setup() {
    const router = useRouter();

    function navigateHome() {
      router.push("");
    }

    return {
      navigateHome,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/main.scss";

.layout {
  background-color: #7089ae;
  background-position: 0% 75%;
  background-size: cover;
  min-height: 90vh;
  width: -webkit-fill-available;
  display: grid;
  grid-template-rows: max-content;
  grid-row-gap: 5vh;
  justify-items: center;
  padding: 5vh 5vw;

  .content {
    display: grid;
    grid-template-rows: max-content;
    grid-row-gap: 5vh;
    justify-items: center;

    button {
      color: #222 !important;
      justify-self: left;
      font-size: 1.25rem;
      font-family: "Times New Roman", Times, serif;
      -webkit-filter: drop-shadow(1px 1px 1px #222);
      filter: drop-shadow(1px 1px 1px #222);

      @media (min-width: $desktop) {
        font-size: 1.5rem;
      }

      &::before {
        position: relative;
        content: "";
        display: inline-block;
        width: 0.4em;
        height: 0.4em;
        border-right: 0.2em solid #222;
        border-top: 0.2em solid #222;
        transform: rotate(-135deg);
        margin-right: 0.5em;
      }

      &:hover {
        color: #4f4f4f !important;

        &::before {
          border-right: 0.2em solid #4f4f4f;
          border-top: 0.2em solid #4f4f4f;
          transition: 0.3s;
        }
      }
    }
  }
}
</style>
